import React from 'react'
import Embed from './Embed';

const Strategy = () => {

  const StrategyUrl = "https://docs.google.com/document/d/1roZAuv4ZjDwpSVZsZzVgwFElbE9M-2rh1CjGyp_kEhk/";

  return (
    <div className="content strategy">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={StrategyUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Strategy