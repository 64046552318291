import React from 'react';
import Embed from './Embed';

const LongtermStrategy = ()  => {

  const LongtermStrategyUrl = "https://docs.google.com/document/d/1FCAR7C_09xNdwWm5fzL45no-qpfZn8usmxTRF96PORU/";

  return (    
    <div className="content strategy-longterm">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={LongtermStrategyUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LongtermStrategy