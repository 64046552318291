import React from 'react'
import Embed from './Embed';

const TaskBoard = () => {

  const TaskBoardUrl = "https://airtable.com/embed/applODMiIWYEciuGV/shrxBKypslU7iDm4V?backgroundColor=grayLight&viewControls=on";

  return (    
    <div className="content task-board">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              
              <p>
                <a href="https://airtable.com/applODMiIWYEciuGV/tble9Ee2tmD3t9Vlc/viwYD6gDVbUlDYKL8?blocks=hide" target="_blank">VIEW LIVE TABLE</a>
              </p>

              <Embed src={TaskBoardUrl} embedHeight="calc(100vw* 2250 / 1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskBoard