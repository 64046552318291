import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getToken } from './auth';

function PrivateRoute({ component: Component, allowedRoles, user, logout, currentPageComponent, ...rest }) {
  const userToken = getToken();
  const userRole = decodeUserRole(userToken);

  function decodeUserRole(token) {
    if (token) {
      const tokenParts = token.split('.');
      if (tokenParts.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          return payload.role;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    }
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userToken) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        if (!allowedRoles.includes(userRole)) {
          if (userRole === 'admin') {
            return <Redirect to="/admin" />;
          } else if (userRole === 'client') {
            return <Redirect to="/client" />;
          } else {
            return <Redirect to="/" />;
          }
        }

        return <Component {...props} user={user} currentPageComponent={currentPageComponent} logout={logout} />;
      }}
    />
  );
}

export default PrivateRoute;
