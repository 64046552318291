import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faSpinnerThird, faUserTie, faLock } from '@fortawesome/pro-light-svg-icons';
import './css/login.css';
import aboveLogo from './img/logo.png';

function LoginPage({ login, user }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
    setLoginError(null);

    try {
      await login(username, password, e.target);
      if (isMounted.current) {
        setLoginError(null);
      }
    } catch (error) {
      console.error('Something went wrong. Please contact the administrator.', error);
      if (isMounted.current) {
        setLoginError('Login failed. Please check your username and password.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoggingIn(false);
      }
    }
  };

  if (user) {
    return <Redirect to={user.role === 'admin' ? '/admin' : '/dashboard'} />;
  }

  return (
    <section id="login">
      <div className="container full">
        <div className="row">
          <div className="col col-100">
            <div className="form-holder">
              <div id="logo">
                <div className="img-holder">
                  <img src={aboveLogo} alt="Above Digital Logo" />
                </div>
              </div>
              <form onSubmit={handleLogin}>
                <div>
                  <label htmlFor="username" className="visually-hidden">Username</label>
                  <span className="field-icon"><FontAwesomeIcon icon={faUserTie} /></span>
                  <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    aria-label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="visually-hidden">Password</label>
                  <span className="field-icon"><FontAwesomeIcon icon={faLock} /></span>                  
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    aria-label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit">
                  {isLoggingIn ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : <FontAwesomeIcon icon={faRightToBracket} />}
                </button>
              </form>
              {loginError && <p className="error-message">{loginError}</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;