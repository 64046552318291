import React from 'react'

const ComingSoon = () => {
  return (
    <div className="content coming-soon">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <h1>Coming soon...</h1>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon