import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { loginApi } from './api';
import { setToken, removeToken } from './auth';
import PrivateRoute from './PrivateRoute'; 
import LoginPage from './LoginPage';
import AdminDashboard from './admin/AdminDashboard';
import AdminClients from './admin/AdminClients';
import './css/globals.css';
import './css/fonts.css';
import Dashboard from './mrt/Dashboard';
import LongtermStrategy from './mrt/LongtermStrategy';
import Strategy from './mrt/Strategy';
import ShootsUpcoming from './mrt/ShootsUpcoming';
import Campaigns from './mrt/Campaigns';
import Website from './mrt/Website';
import ShootsPastPhotos from './mrt/ShootsPastPhotos';
import Calendar from './mrt/Calendar';
import AgendaWorkflows from './mrt/AgendaWorkflows';
import TaskBoard from './mrt/TaskBoard';
import SalesData from './mrt/SalesData';
import Influencers from './mrt/Influencers';
import WebsiteCopy from './mrt/WebsiteCopy';
import Workflows from './mrt/Workflows';
import MaterialsBanners from './mrt/MaterialsBanners';
import MaterialsPosters from './mrt/MaterialsPosters';
import MaterialsBrochures from './mrt/MaterialsBrochures';
import MaterialsStickers from './mrt/MaterialsStickers';
import MaterialsBusinessCards from './mrt/MaterialsBusinessCards';
import MaterialsPricingGuide from './mrt/MaterialsPricingGuide';
import MaterialsBrandBook from './mrt/MaterialsBrandBook';
import MaterialsEventMaterials from './mrt/MaterialsEventMaterials';
import ShootsPastVideos from './mrt/ShootsPastVideos';
import ComingSoon from './mrt/ComingSoon';
import Socials from './mrt/Socials';
import MrtBuilds from './mrt/MrtBuilds';
import CalendarTeam from './mrt/CalendarTeam';
import Recordings from './mrt/Recordings';
import CadFiles from './mrt/CadFiles';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('userToken');
    if (storedToken) {
      const decodedToken = JSON.parse(atob(storedToken.split('.')[1]));
      setUser(decodedToken);
    }
  }, []); // Empty dependency array ensures this runs only once

  const login = async (username, password) => {
    try {
      const token = await loginApi(username, password);
      setToken(token);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUser(decodedToken);
      localStorage.setItem('userToken', token); // Save token to localStorage
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = () => {
    removeToken();
    setUser(null);
    localStorage.removeItem('userToken'); // Remove token from localStorage
  };

  return (
    <Router>
      <Switch>

        <Route exact path="/login">
          <LoginPage login={login} user={user} />
        </Route>

        <PrivateRoute path="/admin/clients" user={user} allowedRoles={['admin']} component={AdminClients} />

        <PrivateRoute path="/admin" user={user} allowedRoles={['admin']} component={AdminDashboard} logout={logout} />

        <PrivateRoute path="/marketing-coordinator-tasks" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<TaskBoard />} 
           logout={logout} />

        <PrivateRoute path="/agenda-workflows" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<AgendaWorkflows />} 
           logout={logout} />

        <PrivateRoute path="/workflows" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Workflows />} 
           logout={logout} />

        <PrivateRoute path="/dashboard" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<SalesData />} 
          logout={logout} 
        />

        <PrivateRoute path="/data/website-orders" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<SalesData />} 
          logout={logout} 
        />

        <PrivateRoute path="/campaigns" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Campaigns />} 
           logout={logout} />

        <PrivateRoute path="/influencers" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Influencers />} 
           logout={logout} />

        <PrivateRoute path="/print-materials/banners" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsBanners />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/posters" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsPosters />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/brochures" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsBrochures />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/stickers" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsStickers />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/business-cards" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsBusinessCards />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/pricing-guide" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsPricingGuide />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/brand-book" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsBrandBook />} 
          logout={logout} 
        />

        <PrivateRoute path="/print-materials/event-materials" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<MaterialsEventMaterials />} 
          logout={logout} 
        />

        <PrivateRoute path="/shoots-upcoming" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<ShootsUpcoming />} 
           logout={logout} />

        <PrivateRoute path="/shoots-past-photos" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<ShootsPastPhotos />} 
           logout={logout} />

        <PrivateRoute path="/shoots-past-videos" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<ShootsPastVideos />} 
           logout={logout} />

        <PrivateRoute path="/showrooms" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<ComingSoon />} 
           logout={logout} />

        <PrivateRoute path="/strategy-longterm" 
          user={user} 
          allowedRoles={['client']} 
          component={Dashboard} 
          currentPageComponent={<LongtermStrategy />} 
          logout={logout} />

        <PrivateRoute path="/strategy" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Strategy />} 
           logout={logout} />

        <PrivateRoute path="/socials" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Socials />} 
           logout={logout} />

        <PrivateRoute path="/website" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Website />} 
           logout={logout} />

        <PrivateRoute path="/website-copy" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<WebsiteCopy />} 
           logout={logout} />

        <PrivateRoute path="/mrt-builds" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<MrtBuilds />} 
           logout={logout} />

        <PrivateRoute path="/calendar" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Calendar />} 
           logout={logout} />

        <PrivateRoute path="/calendar-team" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<CalendarTeam />} 
           logout={logout} />

        <PrivateRoute path="/recordings" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<Recordings />} 
           logout={logout} />

        <PrivateRoute path="/cad-files" 
           user={user} 
           allowedRoles={['client']} 
           component={Dashboard} 
           currentPageComponent={<CadFiles />} 
           logout={logout} />

        <Route path="/">
          <Redirect to="/login" />

        </Route>
      </Switch>
    </Router>
  );
}

export default App;
