import React, { useEffect, useRef } from 'react';

const Embed = ({ src, embedHeight }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleLoad = () => {
      console.log('Iframe loaded successfully');
    };

    const handleError = () => {
      console.error('Error loading iframe');
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      iframe.addEventListener('error', handleError);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
        iframe.removeEventListener('error', handleError);
      }
    };
  }, []);

  return (
    <div className="embed-container">
      <iframe
        ref={iframeRef}
        className="embed"
        src={src}
        width="100%"
        height="100%"
        style={{ background: 'transparent', border: 'none', height: embedHeight }}
        title="Iframe Embed"
      ></iframe>
    </div>
  );
}

export default Embed;
