import React from 'react'
import Embed from './Embed';
import MaterialsSideNav from './MaterialsSideNav';

const MaterialsEventMaterials = () => {

  const MaterialsEventMaterialsEmbedUrl = "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FFsLvCN0i26FOSuQjQWrShF%2FTradeshow-Collateral%3Fpage-id%3D1562%253A2%26node-id%3D1562-10123%26viewport%3D3786%252C2491%252C0.14%26t%3DbmZdj7XIhothIpXW-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1562%253A4014";
  
  const MaterialsEventMaterialsUrl = "/print-materials/event-materials";

  return (
    <div className="content materials-banners library">
      <div className="container">
        <div className="row">
          <div className="col col-10">
            <MaterialsSideNav activeItem={MaterialsEventMaterialsUrl} />
          </div>
          <div className="col col-90">
            <section>              
              <Embed src={MaterialsEventMaterialsEmbedUrl} embedHeight="calc(100vw * 1125/1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialsEventMaterials