import React from 'react'
import Embed from './Embed';

const Socials = () => {

  const SocialsUrl = "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F9PRdgfE3ivf0zlrrxkF0GA%2FSocial-Media-Calendar%3Fpage-id%3D256%253A1836%26node-id%3D256-2468%26viewport%3D-6390%252C13112%252C0.25%26t%3DPBgrSLhm3reSbFBX-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D256%253A2468";

  return (
    <div className="content strategy">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={SocialsUrl} embedHeight="calc(100vw* 1250 / 1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Socials