import React from 'react'
import Embed from './Embed';

const ShootsUpcoming = () => {

  const ShootsUrl = "https://airtable.com/embed/appCNHueG82CqKNni/shrmAgUHsqrDdqJxh?backgroundColor=grayLight&viewControls=on";

  return (    
    <div className="content shoots">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={ShootsUrl} embedHeight="calc(100vw * 2250/1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShootsUpcoming