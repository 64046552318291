import React from 'react'
import Embed from './Embed';

const Workflows = () => {

  const WorkflowsUrl = "https://docs.google.com/document/d/1ka9fPKt9Raim22iz3p2rpo3Msa_sPfGuBlXOqOsdD6E/edit";

  return (    
    <div className="content workflows">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={WorkflowsUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workflows