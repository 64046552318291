import React from 'react'
import Embed from './Embed';

const Calendar = () => {

  const CalendarUrl = "https://airtable.com/embed/appBVhgQXX9HOmLq0/shrgV4bVUVmHl8lzO?backgroundColor=grayLight&viewControls=on";

  return (    
    <div className="content calendar">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={CalendarUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar