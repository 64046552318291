import React from 'react'
import Embed from './Embed';

const MrtBuilds = () => {

  const MrtBuildsUrl = "https://airtable.com/embed/appCNHueG82CqKNni/shr8iVQ1ezCN2EBTz?viewControls=on";

  return (
    <div className="content mrt-builds">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              
              <p>
                <a href="https://airtable.com/appCNHueG82CqKNni/tblt7WDw3XcfiEbJs/viw1tupzqoAUZCAN4?blocks=hide" target="_blank">VIEW LIVE TABLE</a>
              </p>
              
              <Embed src={MrtBuildsUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MrtBuilds