import axios from 'axios';

const apiUrl = 'http://rebuild.abovedigital.co/api';

export async function loginApi(username, password) {
  try {
    const response = await axios.post(`${apiUrl}/login`, { username, password });
    return response.data.token;
  } catch (error) {
    throw error.response ? error.response.data.message : error.message;
  }
}
