import React, { useState } from 'react'
import Embed from './Embed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-light-svg-icons';

const ShootsPastPhotos = () => {
  const [iframeSrc, setIframeSrc] = useState('https://workdrive.zohopublic.com.au/embed/pmqef74b5e3a8db024ffabf20dc1f86c17260?toolbar=false&layout=grid&appearance=light&themecolor=green');

  const handleLinkClick = (event, newSrc) => {
    event.preventDefault();
    setIframeSrc(newSrc);
  };

  const links = [
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef74b5e3a8db024ffabf20dc1f86c17260?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Tradesman',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef5b93fac0fe41433891286cf772949269?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Explorer',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef085198ddfdb2465abbe0c2f618610810?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Initiator',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqefcd670a74b55646d19b005d1f2bda40d8?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Companion',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqefc55e4dcc88494b1ca5c5cbf2f85ba051?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Battery World',
    },
  ];

  const buttons = [
    {
      href: 'https://www.figma.com/proto/vlBK1j6WFA3348H7Aa0CTH/MRT-Content-Plan-Jun---Dec-2023?page-id=117%3A2&type=design&node-id=117-3&viewport=629%2C-3520%2C0.57&t=kdmC3IKaADg5tHUn-1&scaling=contain&starting-point-node-id=117%3A3',
      title: 'Content Plan',
      desc: 'Here is the live link to the latest content plan. For a PDF version of this please contact your account manager.',
    },
    {
      href: 'https://airtable.com/appgpj1LdByqr2bN5/tblB0K8uIlCUY63GY/viwz34oMHxvThsXjo?blocks=hide',
      title: 'Content Ideas',
      desc: 'Here is where we store all of the ideas the in-house team or Above Digital has for future content creation.',
    },
    {
      href: 'https://airtable.com/appCDJsInAHlutSkB/tblZIg5zMeEUaEqd3/viwRSZhvZillXtJlD?blocks=hide',
      title: 'Content Requirements',
      desc: 'Here is where we store all of our current content requirements for all marketing efforts. This is used in our brainstorm sessions in content reviews.',
    },
    {
      href: 'https://ourglobalteamcomau-my.sharepoint.com/:f:/g/personal/georgina_abovedigital_co/EvQayjndNI1EsRpIO0cvpe0BNmY5Cl2TP2ng2d0leAXY_Q?e=cizCT4',
      title: 'Professional Content',
      desc: 'Here is where the content taken by Above Digital in professional shoots is stored for the in-house team to be able to download.',
    },
    {
      href: 'https://ourglobalteamcomau-my.sharepoint.com/:f:/g/personal/georgina_abovedigital_co/Emw5AmOY4JJLu2Gw1SDjmBkB61RmWl06mlt5TXnIkRNb4w?e=xDuGR9',
      title: 'In-house Content',
      desc: 'Here is where the in-house team upload any content they have taken that the Above Digital team could use in their marketing efforts.',
    },
    {
      href: 'https://ourglobalteamcomau-my.sharepoint.com/:f:/g/personal/georgina_abovedigital_co/EoNbyTx87_REgrp70JaqDakBQf7fUQCnTovbwFbLjYNaCg?e=DwK9lw',
      title: 'Organic Social Campaign Files',
      desc: 'Here is where the organic social media images from the campaigns are stored, for the in-house team to distribute to the social channels.',
    },
    {
      href: 'https://ourglobalteamcomau-my.sharepoint.com/:f:/g/personal/georgina_abovedigital_co/EoOAO7ajZNJMilwQq1ERzDsBGzrpk_egaxLn2EVwAWlspA?e=VXfdfI',
      title: 'Organic Social Files',
      desc: 'A folder Catherine + Georgie created to put quality media that Georgie comes across that could be used for the in-house team in their organic social strategy.',
    },
    {
      href: 'https://ourglobalteamcomau-my.sharepoint.com/:f:/g/personal/georgina_abovedigital_co/ElxqK_Fe1zpLuQ4mS4zUxlsBqrhazKRqxsCnNS0wWsvHrg?e=uKTcQ6',
      title: 'Logo + Design Files',
      desc: 'Here is where the business logo files and other design assets are stored for the in-house team to access.',
    },
  ];

  return (    
    <div className="content photos library light">
      <div className="container">
        <div className="row">
          <div className="col col-10">
            <ul className="library-nav">
              {links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className={iframeSrc === link.href ? 'active' : ''}
                  onClick={(event) => handleLinkClick(event, link.href)}
                >
                  {link.text}
                </a>
              </li>
              ))}
            </ul>
          </div>
          <div className="col col-90">
            <Embed src={iframeSrc} embedHeight="calc(100vw* 3600 / 1900)" />
          </div>
        </div>
        <div className="row">
          <div className="col col-100">
            <section className="btns-holder">
              {buttons.map((button, index) => (
                <a
                  href={button.href}
                  className="btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <span className="name">{button.title}</span>
                  <span className="desc">{button.desc}</span>
                  <FontAwesomeIcon icon={faImage} />
                </a>
              ))}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShootsPastPhotos