import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faRightFromBracket, faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import aboveLogo from '../img/logo.png';

const TopNav = ({ user, logout }) => {
  const [clientFirstName, setClientFirstName] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [viewType, setViewType] = useState(sessionStorage.getItem('viewType') || 'admin');
  const apiUrl = 'http://localhost:5000/api';
  const [activeLink, setActiveLink] = useState('');

  const navItems = [
    { path: '/marketing-coordinator-tasks', label: 'Marketing Coordinator Tasks', type: 'team' },
    { path: '/agenda-workflows', label: 'Agenda & Workflows', type: 'admin' },
    { path: '/workflows', label: 'Workflows', type: 'team' },
    {
      path: '/dashboard',
      label: 'Data',
      type: 'admin team',
      subItems: [
        { path: '/data/website-orders', label: 'Website Orders' },
        { path: '/data/website-traffic', label: 'Website Traffic' },
      ]
    },
    { path: '/campaigns', label: 'Campaigns', type: 'admin' },
    { path: '/influencers', label: 'Influencers', type: 'admin team' },
    {
      path: '#',
      label: 'Print Materials',
      type: 'admin team',
      subItems: [
        { path: '/print-materials/banners', label: 'Banners' },
        { path: '/print-materials/posters', label: 'Posters' },
        { path: '/print-materials/brochures', label: 'Brochures' },
        { path: '/print-materials/stickers', label: 'Stickers' },
        { path: '/print-materials/business-cards', label: 'Business Cards' },
        { path: '/print-materials/pricing-guide', label: 'Pricing Guide' },
        { path: '/print-materials/brand-book', label: 'Brand Book' },
        { path: '/print-materials/event-materials', label: 'Event Materials' },
      ]
    },
    {
      path: '#',
      label: 'Shoots',
      type: 'admin team',
      subItems: [
        { path: '/shoots-upcoming', label: 'Upcoming Shoots' },
        { path: '/shoots-past-photos', label: 'Past Shoots (Photos)' },
        { path: '/shoots-past-videos', label: 'Past Videos (Videos)' },
      ]
    },
    { path: '/showrooms', label: 'Showrooms', type: 'admin team' },
    { path: '/strategy-longterm', label: 'Strategy', type: 'admin' },
    { path: '/strategy', label: 'Strategy', type: 'team' },
    { path: '/socials', label: 'Socials', type: 'admin team' },
    { path: '/website', label: 'Website', type: 'admin team' },
    {
      path: '#',
      label: 'Copy Review',
      type: 'admin team',
      subItems: [
        { path: '/website-copy', label: 'Website Copy' },
        { path: '/mrt-builds', label: 'MRT Builds' },
      ]
    },
    { path: 'https://docs.google.com/document/d/1FCAR7C_09xNdwWm5fzL45no-qpfZn8usmxTRF96PORU/edit#heading=h.4touwo3ov7qw', label: 'Product Road Map', type: 'admin team' },
    { path: '/calendar', label: 'Calendar', type: 'admin' },
    { path: '/calendar-team', label: 'Calendar', type: 'team' },
    {
      path: '#',
      label: 'Darren Assets',
      type: 'admin', 
      subItems: [
        { path: '/recordings', label: 'Recordings' },
        { path: '/cad-files', label: 'CAD Files' },
        { path: 'https://airtable.com/appuVNAuqIWiBz7zS/tblgjtQ2hQ32ApUL1/viwyE3ZUoMt7OkLG6?blocks=show', label: 'Expert Feedback' },
      ]
    },
    { path: 'https://airtable.com/appuVNAuqIWiBz7zS/tblgjtQ2hQ32ApUL1/viwZKtNuLydDF3DbN?blocks=hide', label: 'Ask an Expert', type: 'team' },
  ];

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMenuOpen(false); // Close the menu when a link is clicked
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    if (user && user.role === 'client') {
      axios.get(`${apiUrl}/user/${user.id}`)
        .then(response => {
          if (isMounted) {
            setClientFirstName(response.data.fname);
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }

    return () => {
      isMounted = false; // Clean up on unmount
    };
  }, [user]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleViewType = () => {
    const newViewType = viewType === 'admin' ? 'team' : 'admin';
    setViewType(newViewType);
    sessionStorage.setItem('viewType', newViewType);
  };

  const findPageName = (path) => {
    for (let item of navItems) {
      if (item.path === path) {
        return item.label;
      }
      if (item.subItems) {
        for (let subItem of item.subItems) {
          if (subItem.path === path) {
            return subItem.label;
          }
        }
      }
    }
    return 'Home';
  };

  const filteredNavItems = navItems.filter(item => item.type.includes(viewType));

  return (
    <header>
      <div className="top-nav">
        <div id="logo">
          <a href="/dashboard" className="img-holder">
            <img src={aboveLogo} alt="Above Digital Logo" draggable="false" />
          </a>
        </div>
        <div className="profile-main-holder">
          <div className="profile-holder">
            <div className="profile">
              <span className="profile-image">{clientFirstName.charAt(0).toUpperCase()}</span>
              <span className="profile-name">{clientFirstName}</span>
            </div>
            <ul className="profile-menu">
              <li>
                <a href="#">
                  <FontAwesomeIcon icon={faLock} />
                  Security
                </a>
              </li>
              <li>
                <button onClick={logout}>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`main-nav ${menuOpen ? 'open' : ''}`}>
        <h1 className="current-page">{findPageName(activeLink)}</h1>
        <button className="menu-toggle" onClick={toggleMenu} aria-expanded={menuOpen}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </button>
        <nav className="custom-pages">
          <ul>
            {filteredNavItems.map(item => (
              <li
                key={`${item.path}-${item.label}`}
                className={`${item.type} ${activeLink === item.path || (item.subItems && item.subItems.some(subItem => activeLink === subItem.path)) ? 'active' : ''}`}
              >
                <a
                  href={item.path !== "#" ? item.path : undefined}
                  target={item.path.startsWith('http') ? '_blank' : undefined}
                  className={activeLink === item.path ? "active" : ""}
                  onClick={item.path !== "#" ? () => handleLinkClick(item.path) : undefined}
                >
                  {item.label}
                </a>
                {item.subItems && (
                  <ul className="sub">
                    {item.subItems.map(subItem => (
                      <li
                        key={`${subItem.path}-${subItem.label}`}
                        className={`${activeLink === subItem.path ? 'active' : ''}`}
                      >
                        <a
                          href={subItem.path}
                          target={subItem.path.startsWith('http') ? '_blank' : undefined}
                          className={activeLink === subItem.path ? "active" : ""}
                          onClick={() => handleLinkClick(subItem.path)}
                        >
                          {subItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="view-toggle">
            <button onClick={toggleViewType}>
              {viewType === 'admin' ? 'Admin View' : 'Team View'}
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default TopNav;
