import React from 'react'
import Embed from './Embed';

const WebsiteCopy = () => {

  const WebsiteCopyEmbedUrl = "https://airtable.com/embed/appNrBkCLucL8nhvV/shryQCGTSpQFa7rbN?viewControls=on";

  return (
    <div className="content website-copy">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              
              <p>
                <a href="https://airtable.com/appNrBkCLucL8nhvV/tbl9FNlDk2Hmn0pAC/viwvPFOO8EBCv0iKp?blocks=hide" target="_blank">VIEW LIVE TABLE</a>
              </p>
              
              <Embed src={WebsiteCopyEmbedUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteCopy