import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import SideNav from './SideNav.js';
import TopNav from './TopNav.js';
import '../css/dashboard.css';

function AdminDashboard({ user, logout }) {
  const [adminFirstName, setAdminFirstName] = useState('');
  // const [userList, setUserList] = useState([]);
  const apiUrl = 'http://rebuild.abovedigital.co/api';


  useEffect(() => {
    if (user && user.role === 'admin') {
      axios.get(`${apiUrl}/user/${user.id}`)
        .then(response => {
          setAdminFirstName(response.data.fname);
          console.log(setAdminFirstName);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
      
      // Make an API call to fetch the list of users
      // axios.get(`${apiUrl}/users`)
      //   .then(response => {
      //     setUserList(response.data); // Assuming the API returns an array of users
      //   })
      //   .catch(error => {
      //     console.error('Error fetching user list:', error);
      //   });

      //   axios.get(`${apiUrl}/clients`)
      //   .then(response => {
      //     setClientList(response.data);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching client list:', error);
      //   });
    }
  }, [user]);

  // const handleDeleteUser = (userId) => {
  //   // Make an API call to delete user by ID
  //   axios.delete(`${apiUrl}/user/${userId}`)
  //     .then(response => {
  //       console.log(response.data.message);
  //     })
  //     .catch(error => {
  //       console.error('Error deleting user:', error); 
  //     });
  // };

  return (

    <div id="dashboard">
      <SideNav />
      <main>
        <TopNav user={user} logout={logout} />
        <content>
          <section className="greeting">
            <div className="container">
              <div className="row">
                <div className="col col-100">
                  <h2 className="title size-biggest weight-light">Hello, {adminFirstName}!</h2>
                  <p className="desc size-medium weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
              </div>
            </div>
          </section>
        </content>
      </main>
    </div>
    
  );
}

export default AdminDashboard;