import React from 'react'
import Embed from './Embed';
import MaterialsSideNav from './MaterialsSideNav';

const MaterialsBrandBook = () => {

  const MaterialsBrandBookEmbedUrl = "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FFsLvCN0i26FOSuQjQWrShF%2FTradeshow-Collateral%3Fpage-id%3D1677%253A512%26node-id%3D1677-623%26viewport%3D3303%252C731%252C0.19%26t%3Dr8dFccKEaDc6rYGt-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1677%253A623";
  
  const MaterialsBrandBookUrl = "/print-materials/brand-book";

  return (
    <div className="content materials-banners library">
      <div className="container">
        <div className="row">
          <div className="col col-10">
            <MaterialsSideNav activeItem={MaterialsBrandBookUrl} />
          </div>
          <div className="col col-90">
            <section>              
              <Embed src={MaterialsBrandBookEmbedUrl} embedHeight="calc(100vw * 1125/1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialsBrandBook