import React, { useState, useEffect  } from 'react';
import axios from 'axios';

const AdminClients = ({ user }) => {
  const [clientList, setClientList] = useState([]);
  const apiUrl = 'http://rebuild.abovedigital.co/api';

  useEffect(() => {
    if (user && user.role === 'admin') {
        axios.get(`${apiUrl}/clients`)
        .then(response => {
          setClientList(response.data);
        })
        .catch(error => {
          console.error('Error fetching client list:', error);
        });
    }
  }, [user]);

  return (
    <>
    <h2>Client List</h2>
    <ul>
      {clientList.map(client => (
        <li key={client.client_id}>
          Name: {client.client_name}
          Company: {client.client_company_name}
        </li>
      ))}
    </ul>
    </>
  )
}

export default AdminClients