import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faGear, faRightFromBracket, faBell } from '@fortawesome/pro-light-svg-icons';

const TopNav = ({ user, logout }) => {

  const [adminFirstName, setAdminFirstName] = useState('');
  const apiUrl = 'http://rebuild.abovedigital.co/api';

  useEffect(() => {
    if (user && user.role === 'admin') {

      axios.get(`${apiUrl}/user/${user.id}`)
        .then(response => {
          setAdminFirstName(response.data.fname);
          console.log(setAdminFirstName);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [user]);

  return (
    <header>

      <div className="profile-main-holder">
        <div className="notifications">
          <button>
            <span className="notif-count">1</span>
            <FontAwesomeIcon icon={faBell} />
          </button>
        </div>
        <div className="profile-holder">
          <div className="profile">
            <span className="profile-image"></span>
            <span className="profile-name">{adminFirstName}</span>
          </div>
          <ul className="profile-menu">
            <li>
              <a href="#">
                <FontAwesomeIcon icon={faLock} />
                Security
              </a>
            </li>
            <li>
              <a href="#">
                <FontAwesomeIcon icon={faGear} />
                Options
              </a>
            </li>
            <li>
              <button onClick={logout}>
              <FontAwesomeIcon icon={faRightFromBracket} />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default TopNav