import React from 'react'
import Embed from './Embed';
import MrtAdventuresTitle from '../img/mrt-adventures-influencers.webp';

const Influencers = () => {

  const InfluencersUrl = "https://airtable.com/embed/appTh7pnX1IYSu9sR/shrdtsovDjz5sgWSs?viewControls=on";

  return (
    <div className="content influencers">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>
              <figure>
                <img src={MrtAdventuresTitle} alt="MRT Adventure - Influencer for portal" draggable="false" />
              </figure>
              <Embed src={InfluencersUrl} embedHeight="calc(100vw * 1250 / 1900)" />
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Influencers