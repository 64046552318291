import React, { useState } from 'react';

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="sidebar" className={isOpen ? 'active' : ''}>
      <div className="sidebar-overlay" onClick={toggleSidebar}></div>

      <div className="sidebar-container">
        <a href="javascript:void(0);" className="sidebar-toggle" onClick={toggleSidebar}>CONTACTS</a>

        <div className="content">
          <h4 className="above-subtitle">Contacts</h4>

          <div className="contact">
            <h6 className="contact-name">Georgie</h6>
            <p className="contact-phone"><a href="tel:+61421804555">+61 421 804 555</a></p>
            <p className="contact-email"><a href="mailto:georgina@abovedigital.co">georgina@abovedigital.co</a></p>
          </div>

          <div className="contact">
            <h6 className="contact-name">Lee</h6>
            <p className="contact-phone"><a href="tel:+61402254338">+61 402 254 338</a></p>
            <p className="contact-email"><a href="mailto:lee@abovedigital.co">lee@abovedigital.co</a></p>
          </div>

          <div className="contact">
            <h6 className="contact-name">Software</h6>
            <p className="contact-phone">Availability<br />Mon-Sat: 8am-6pm</p>
            <p className="contact-email"><a href="mailto:software@abovedigital.co">software@abovedigital.co</a></p>
          </div>

          <div className="contact">
            <h6 className="contact-name">Website Development</h6>
            <p className="contact-phone">Availability<br />Mon-Fri: 9am-6pm</p>
            <p className="contact-email"><a href="mailto:dev@abovedigital.co">dev@abovedigital.co</a></p>
          </div>

          <div className="contact">
            <h6 className="contact-name">Graphic Design</h6>
            <p className="contact-phone">Availability<br />Mon-Fri: 9am-6pm</p>
            <p className="contact-email"><a href="mailto:design@abovedigital.co">design@abovedigital.co</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
