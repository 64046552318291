import React, { useState, useEffect } from 'react';
import { fetchAirtableData } from './../airtableAPI';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO, compareAsc, parse } from 'date-fns';
import '../css/dashboard.css'; // Ensure to import the spinner CSS
import { debounce } from 'lodash';

const SalesDataComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [weekOptions, setWeekOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('All Locations');
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedLocationMonth, setSelectedLocationMonth] = useState(null);
  const [selectedPieMonth1, setSelectedPieMonth1] = useState(null);
  const [selectedPieMonth2, setSelectedPieMonth2] = useState(null);
  const [view, setView] = useState('Dollar'); // New state for view toggle

  useEffect(() => {
    const getData = async () => {
      setLoading(true); // Set loading state at the beginning
      try {
        const records = await fetchAirtableData();
  
        if (records.length > 0) {
          // Process records to extract unique values and latest date
          const latestDate = records.reduce((latest, record) => {
            const date = parseISO(record.fields.Date);
            return date > latest ? date : latest;
          }, parseISO(records[0].fields.Date));
  
          const uniqueWeekEndings = Array.from(new Set(records.map(record => record.fields["Week Ending"])))
            .sort((a, b) => compareAsc(parseISO(a), parseISO(b)));
  
          const uniqueLocations = Array.from(new Set(records.map(record => record.fields.Location)));
  
          const uniqueMonths = Array.from(new Set(records.map(record => format(parseISO(record.fields.Date), 'MMMM yyyy'))))
            .sort((a, b) => compareAsc(parseISO(a), parseISO(b))).reverse();
  
          // Batch state updates
          setData(records);
          setSelectedDate(latestDate);
          setWeekOptions(uniqueWeekEndings);
          setSelectedWeek(uniqueWeekEndings[uniqueWeekEndings.length - 1]);
          setLocationOptions(['All Locations', ...uniqueLocations]);
          setMonthOptions(uniqueMonths);
          setSelectedMonth(uniqueMonths[0]);
          setSelectedLocationMonth(uniqueMonths[0]);
          setSelectedPieMonth1(uniqueMonths[0]);
          setSelectedPieMonth2(uniqueMonths[0]);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setLoading(false); // Ensure loading state is set to false at the end
      }
    };
  
    // Use debounce to avoid rapid state updates if necessary
    const debouncedGetData = debounce(getData, 300);
  
    debouncedGetData();
  
    // Cleanup function to cancel debounce on component unmount
    return () => {
      debouncedGetData.cancel();
    };
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleLocationMonthChange = (event) => {
    setSelectedLocationMonth(event.target.value);
  };

  const handlePieMonthChange1 = (event) => {
    setSelectedPieMonth1(event.target.value);
  };

  const handlePieMonthChange2 = (event) => {
    setSelectedPieMonth2(event.target.value);
  };

  const handleViewToggle = () => {
    setView(view === 'Dollar' ? 'Quantity' : 'Dollar');
  };

  const processMonthlyData = (records, viewType) => {
    const aggregatedData = {
      monthly: {},
      weekly: {},
      monthlyByLocation: {},
      totalMonthlySales: {},
      locationPackages: {},
    };

    records.forEach(record => {
      const { Date: date, "Product Type": type, Package: packageType, Location: location } = record.fields;
      const amountSold = viewType === 'Dollar' ? record.fields.Value : record.fields.Quantity;
      const month = format(parseISO(date), 'MMMM yyyy');
      const yearMonth = format(parseISO(date), 'yyyy-MM');
      const weekEnding = record.fields["Week Ending"] ? format(parseISO(record.fields["Week Ending"]), 'yyyy-MM-dd') : null;

      if (!aggregatedData.monthly[yearMonth]) {
        aggregatedData.monthly[yearMonth] = {
          monthLabel: month,
          types: { Standard: 0, 'X-Series': 0 },
          packages: {
            "Explorer Package": 0,
            "Initiator Package": 0,
            "Tradesman Package": 0,
            "Companion Package": 0,
            "Individual Chassis Mount Orders": 0,
            "Others": 0
          }
        };
      }

      if (!aggregatedData.monthlyByLocation[yearMonth]) {
        aggregatedData.monthlyByLocation[yearMonth] = {};
      }

      if (!aggregatedData.monthlyByLocation[yearMonth][location]) {
        aggregatedData.monthlyByLocation[yearMonth][location] = { Standard: 0, 'X-Series': 0 };
      }

      if (!aggregatedData.totalMonthlySales[yearMonth]) {
        aggregatedData.totalMonthlySales[yearMonth] = 0;
      }

      if (!aggregatedData.locationPackages[yearMonth]) {
        aggregatedData.locationPackages[yearMonth] = {};
      }

      if (!aggregatedData.locationPackages[yearMonth][location]) {
        aggregatedData.locationPackages[yearMonth][location] = {
          "Explorer Package": 0,
          "Initiator Package": 0,
          "Tradesman Package": 0,
          "Companion Package": 0,
          "Individual Chassis Mount Orders": 0,
          "Others": 0
        };
      }

      if (type === 'Standard' || type === 'X-Series') {
        aggregatedData.monthly[yearMonth].types[type] += amountSold;
        aggregatedData.monthlyByLocation[yearMonth][location][type] += amountSold;
      }

      aggregatedData.totalMonthlySales[yearMonth] += amountSold;

      if (aggregatedData.monthly[yearMonth].packages[packageType] !== undefined) {
        aggregatedData.monthly[yearMonth].packages[packageType] += amountSold;
        aggregatedData.locationPackages[yearMonth][location][packageType] += amountSold;
      }

      if (weekEnding) {
        if (!aggregatedData.weekly[weekEnding]) {
          aggregatedData.weekly[weekEnding] = {
            weekLabel: `Week ending ${weekEnding}`,
            types: { Standard: 0, 'X-Series': 0 },
            packages: {
              "Explorer Package": 0,
              "Initiator Package": 0,
              "Tradesman Package": 0,
              "Companion Package": 0,
              "Individual Chassis Mount Orders": 0,
              "Others": 0
            }
          };
        }

        if (type === 'Standard' || type === 'X-Series') {
          aggregatedData.weekly[weekEnding].types[type] += amountSold;
        }

        if (aggregatedData.weekly[weekEnding].packages[packageType] !== undefined) {
          aggregatedData.weekly[weekEnding].packages[packageType] += amountSold;
        }
      }
    });

    const sortedMonthlyKeys = Object.keys(aggregatedData.monthly).sort((a, b) => compareAsc(new Date(a), new Date(b)));
    const monthlyLabels = sortedMonthlyKeys.map(key => aggregatedData.monthly[key].monthLabel);
    const selectedMonthData = aggregatedData.monthly[format(selectedDate, 'yyyy-MM')] || { types: { Standard: 0, 'X-Series': 0 }, packages: {} };

    const sortedWeeklyKeys = Object.keys(aggregatedData.weekly).sort((a, b) => compareAsc(new Date(a), new Date(b)));
    const weeklyLabels = sortedWeeklyKeys.map(key => aggregatedData.weekly[key].weekLabel);
    const selectedWeekData = aggregatedData.weekly[selectedWeek] || { types: { Standard: 0, 'X-Series': 0 }, packages: {} };

    const selectedMonthlyByLocationData = aggregatedData.monthlyByLocation[format(parse(selectedMonth, 'MMMM yyyy', new Date()), 'yyyy-MM')] || {};
    const selectedPieMonthData1 = aggregatedData.monthly[format(parse(selectedPieMonth1, 'MMMM yyyy', new Date()), 'yyyy-MM')].packages || {};
    const selectedPieMonthData2 = aggregatedData.monthly[format(parse(selectedPieMonth2, 'MMMM yyyy', new Date()), 'yyyy-MM')].packages || {};

    const barChartData = {
      labels: monthlyLabels,
      datasets: [
        {
          label: 'Standard',
          data: sortedMonthlyKeys.map(key => aggregatedData.monthly[key] ? aggregatedData.monthly[key].types.Standard : 0),
          backgroundColor: '#caf270',
          borderColor: '#caf270',
          borderWidth: 1,
        },
        {
          label: 'X-Series',
          data: sortedMonthlyKeys.map(key => aggregatedData.monthly[key] ? aggregatedData.monthly[key].types['X-Series'] : 0),
          backgroundColor: "#45c490",
          borderColor: "#45c490",
          borderWidth: 1,
        },
      ],
    };

    const pieChartData1 = {
      labels: Object.keys(selectedPieMonthData1),
      datasets: [{
        data: Object.values(selectedPieMonthData1),
        backgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ],
        hoverBackgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ]
      }]
    };

    const pieChartData2 = {
      labels: Object.keys(selectedPieMonthData2),
      datasets: [{
        data: Object.values(selectedPieMonthData2),
        backgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ],
        hoverBackgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ]
      }]
    };

    const weeklyBarChartData = {
      labels: weeklyLabels,
      datasets: [
        {
          label: 'Standard',
          data: sortedWeeklyKeys.map(key => aggregatedData.weekly[key] ? aggregatedData.weekly[key].types.Standard : 0),
          backgroundColor: '#caf270',
          borderColor: '#caf270',
          borderWidth: 1,
        },
        {
          label: 'X-Series',
          data: sortedWeeklyKeys.map(key => aggregatedData.weekly[key] ? aggregatedData.weekly[key].types['X-Series'] : 0),
          backgroundColor: "#45c490",
          borderColor: "#45c490",
          borderWidth: 1,
        },
      ],
    };

    const weeklyPieChartData = {
      labels: Object.keys(selectedWeekData.packages),
      datasets: [{
        data: Object.values(selectedWeekData.packages),
        backgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ],
        hoverBackgroundColor: [
          "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
        ]
      }]
    };

    return { barChartData, pieChartData1, pieChartData2, weeklyBarChartData, weeklyPieChartData };
  };

  const processLocationData = (records, viewType) => {
    const aggregatedData = {
      monthlyByLocation: {},
    };

    records.forEach(record => {
      const { Date: date, "Product Type": type, Location: location } = record.fields;
      const amountSold = viewType === 'Dollar' ? record.fields.Value : record.fields.Quantity;
      const yearMonth = format(parseISO(date), 'yyyy-MM');

      if (!aggregatedData.monthlyByLocation[yearMonth]) {
        aggregatedData.monthlyByLocation[yearMonth] = {};
      }

      if (!aggregatedData.monthlyByLocation[yearMonth][location]) {
        aggregatedData.monthlyByLocation[yearMonth][location] = { Standard: 0, 'X-Series': 0 };
      }

      if (type === 'Standard' || type === 'X-Series') {
        aggregatedData.monthlyByLocation[yearMonth][location][type] += amountSold;
      }
    });

    const sortedMonthlyKeys = Object.keys(aggregatedData.monthlyByLocation).sort((a, b) => compareAsc(new Date(a), new Date(b)));
    const monthlyLabels = sortedMonthlyKeys.map(key => format(parseISO(key + '-01'), 'MMMM yyyy'));

    const selectedLocationData = selectedLocation === 'All Locations'
      ? sortedMonthlyKeys.map(key => {
          const monthlyData = aggregatedData.monthlyByLocation[key];
          const totalStandard = monthlyData ? Object.values(monthlyData).reduce((acc, locData) => acc + locData.Standard, 0) : 0;
          const totalXSeries = monthlyData ? Object.values(monthlyData).reduce((acc, locData) => acc + locData['X-Series'], 0) : 0;
          return { Standard: totalStandard, 'X-Series': totalXSeries };
        })
      : sortedMonthlyKeys.map(key => aggregatedData.monthlyByLocation[key] ? aggregatedData.monthlyByLocation[key][selectedLocation] : { Standard: 0, 'X-Series': 0 });

    const totalMonthlySalesBarChartData = {
      labels: monthlyLabels,
      datasets: [
        {
          label: 'Standard',
          data: selectedLocationData.map(data => data ? data.Standard : 0),
          backgroundColor: '#caf270',
          borderColor: '#caf270',
          borderWidth: 1,
        },
        {
          label: 'X-Series',
          data: selectedLocationData.map(data => data ? data['X-Series'] : 0),
          backgroundColor: "#45c490",
          borderColor: "#45c490",
          borderWidth: 1,
        },
      ],
    };

    const pieChartDataByLocation = (selectedMonth) => {
      const monthKey = format(parse(selectedMonth, 'MMMM yyyy', new Date()), 'yyyy-MM');
      const locationData = aggregatedData.monthlyByLocation[monthKey] || {};

      return {
        labels: Object.keys(locationData),
        datasets: [{
          data: Object.values(locationData).map(loc => loc.Standard + loc['X-Series']),
          backgroundColor: [
            "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
          ],
          hoverBackgroundColor: [
            "#caf270", "#45c490", "#008d93", "#f26d6d", "#b53f3f","#d3d0cb"
          ]
        }]
      };
    };

    return { totalMonthlySalesBarChartData, pieChartDataByLocation };
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const formatInteger = (value) => {
    return Math.round(value).toString();
  };

  if (loading) return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );

  if (error) return <p>Error: {error.message}</p>;

  const { barChartData, pieChartData1, pieChartData2, weeklyBarChartData, weeklyPieChartData } = processMonthlyData(data, view);
  const { totalMonthlySalesBarChartData, pieChartDataByLocation } = processLocationData(data, view);

  return (
    <div className="content charts">
      <div className="container" style={{ padding: 32 }}>
        <button onClick={handleViewToggle}>
          Toggle to {view === 'Dollar' ? 'Quantity' : 'Dollar'} View
        </button>
        <div className="row">
          <div className="col col-70">
            <div className="wrapper" style={{ height: 400 }}>
              <h2 className='title'>Monthly View</h2>
              <Bar
                data={barChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      ticks: {
                        callback: function (value) {
                          return view === 'Dollar' ? formatCurrency(value) : formatInteger(value);
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.parsed.y) : formatInteger(context.parsed.y);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="col col-30">
            <div className='wrapper' style={{ height: 400 }}>
              <h2 className='title'>Monthly View - Packages</h2>
              <select
                value={selectedPieMonth1}
                onChange={handlePieMonthChange1}
              >
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <Pie
                data={pieChartData1}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.raw) : formatInteger(context.raw);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-70">
            <div className="wrapper" style={{ height: 400 }}>
              <h2 className='title'>Weekly View</h2>
              <Bar
                data={weeklyBarChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      ticks: {
                        callback: function (value) {
                          return view === 'Dollar' ? formatCurrency(value) : formatInteger(value);
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.parsed.y) : formatInteger(context.parsed.y);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="col col-30">
            <div className='wrapper' style={{ height: 400 }}>
              <h2 className='title'>Weekly View - Packages</h2>
              <select
                value={selectedWeek}
                onChange={handleWeekChange}
              >
                {weekOptions
                  .sort((a, b) => compareAsc(parseISO(b), parseISO(a)))
                  .map((weekEnding) => (
                    <option key={weekEnding} value={weekEnding}>
                      {format(parseISO(weekEnding), 'MMMM dd, yyyy')}
                    </option>
                  ))}
              </select>
              <Pie
                data={weeklyPieChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.raw) : formatInteger(context.raw);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-70">
            <div className="wrapper" style={{ height: 400 }}>
              <h2 className='title'>Monthly Sales by Location</h2>
              <select
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                {locationOptions.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <Bar
                data={totalMonthlySalesBarChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      ticks: {
                        callback: function (value) {
                          return view === 'Dollar' ? formatCurrency(value) : formatInteger(value);
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.parsed.y) : formatInteger(context.parsed.y);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="col col-30">
            <div className='wrapper' style={{ height: 400 }}>
              <h2 className='title'>Monthly Sales by Location</h2>
              <select
                value={selectedLocationMonth}
                onChange={handleLocationMonthChange}
              >
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <Pie
                data={pieChartDataByLocation(selectedLocationMonth)}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          label += view === 'Dollar' ? formatCurrency(context.raw) : formatInteger(context.raw);
                          return label;
                        }
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDataComponent;
