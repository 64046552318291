import React from 'react';
import TopNav from './TopNav';
import '../css/nav.css';
import '../css/dashboard.css';
import '../css/sidebar.css';
import SideBar from './SideBar';

const Dashboard = ({ user, logout, currentPageComponent }) => {
  return (
    <div id="dashboard">
      <main>
        <TopNav user={user} logout={logout} />
        {currentPageComponent}
      </main>
    <SideBar />
    </div>
  )
}
export default Dashboard