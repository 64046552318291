import React from 'react'
import Embed from './Embed';

const Recordings = () => {

  const RecordingsUrl = "https://airtable.com/embed/appf6ZE6Y2bfZXzTC/shrs8JNRfvGw9aMwV?viewControls=on";

  return (    
    <div className="content recordings">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={RecordingsUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recordings