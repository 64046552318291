import React from 'react'
import Embed from './Embed';

const Website = () => {

  const WebsiteUrl = "https://airtable.com/embed/appNrBkCLucL8nhvV/shrZVItHnPt6Qqw7w?backgroundColor=grayLight&viewControls=on";

  return (
    <div className="content website">
      <div className="container">
        <div className="row">
          <div className="col col-80">
            <section>
              <Embed src={WebsiteUrl} />
            </section>
          </div>

          <div className="col col-20">

            <aside className="website-release">

              <h2 className="title">
                Website Release Schedule and Key Focus
              </h2>

              <div className="website-release-item">
                <h3 class="title">Release 1.0 - June 10th 2024</h3>
                <ul class="desc">
                  <li>Searchable pages for options + upgrades</li>
                  <li>Aesthetic update: removing old styling</li>
                </ul>
              </div>

              <div className="website-release-item">
                <h3 class="title">Release 1.1 - July 1st 2024</h3>
                <ul class="desc">
                  <li>Aesthetic update: removing old styling</li>
                  <li>Adjustable shelving</li>
                </ul>
              </div>

              <div className="website-release-item">
                <h3 class="title">Future Release Dates</h3>
                <ul class="desc">
                  <li>1.2 - September 4th 2024</li>
                  <li>1.3 - November 6th 2024</li>
                  <li>1.4 - January 15th 2025</li>
                  <li>1.5 - March 5th 2025</li>
                </ul>
              </div>

            </aside>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Website