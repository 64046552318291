import React from 'react'
import Embed from './Embed';

const AgendaWorkflows = () => {

  const AgendaWorkflowsUrl = "https://docs.google.com/document/d/1ie9J3HxELF6XJiLf5ABzH7rRvfqhDqDS-jSDigDej6g/";

  return (    
    <div className="content agenda-workflows">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={AgendaWorkflowsUrl} embedHeight="calc(100vh - (100vw * 108/1900))" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgendaWorkflows