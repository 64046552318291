import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork, faChartLine, faUsersRectangle, faUserTieHair, faTicket } from '@fortawesome/pro-light-svg-icons';
import aboveLogo from '../img/logo.png';

function SideNav() {
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  // Function to handle link click
  const handleLinkClick = (event, link) => {
    // event.preventDefault(); // Prevent default link behavior
    setActiveLink(link); // Set the active link
  };

  return (
    <aside>
      
      <div id="logo">
        <div className="img-holder">
          <img src={aboveLogo} alt="Above Digital Logo" />
        </div>
      </div>

      <nav>
        <ul>
          <li>
            <a
              href="/admin"
              className={activeLink === "/admin" ? "active" : ""}
              onClick={(e) => handleLinkClick(e, "/admin")}
            >
              <FontAwesomeIcon icon={faChartNetwork} />
              Dashboard
            </a>
          </li>
          <li>
            <a
              href="/admin/clients"
              className={activeLink === "/clients" ? "active" : ""}
              onClick={(e) => handleLinkClick(e, "/clients")}
            >
              <FontAwesomeIcon icon={faUsersRectangle} />
              Clients
            </a>
          </li>
          <li>
            <a
              href="/admin"
              className={activeLink === "/admin" ? "active" : ""}
              onClick={(e) => handleLinkClick(e, "/admin")}
            >
              <FontAwesomeIcon icon={faChartLine} /> 
              Analytics
            </a>
          </li>
          <li>
            <a
              href="/Users"
              className={activeLink === "/Users" ? "active" : ""}
              onClick={(e) => handleLinkClick(e, "/Users")}
            >
              <FontAwesomeIcon icon={faUserTieHair} />
              Users
            </a>
          </li>
          <li>
            <a
              href="/Users"
              className={activeLink === "/Users" ? "active" : ""}
              onClick={(e) => handleLinkClick(e, "/Users")}
            >
              <FontAwesomeIcon icon={faTicket} />
              Support
            </a>
          </li>
        </ul>
      </nav>

    </aside>
  );
}

export default SideNav;
