import React from 'react';

const MaterialsSideNav = ({ activeItem }) => {
  const links = [
    {
      label: 'Banners',
      href: '/print-materials/banners',
    },
    {
      label: 'Posters',
      href: '/print-materials/posters',
    },
    {
      label: 'Brochures',
      href: '/print-materials/brochures',
    },
    {
      label: 'Stickers',
      href: '/print-materials/stickers',
    },
    {
      label: 'Business Cards',
      href: '/print-materials/business-cards',
    },
    {
      label: 'Pricing Guide',
      href: '/print-materials/pricing-guide',
    },
    {
      label: 'Brand Book',
      href: '/print-materials/brand-book',
    },
    {
      label: 'Event Materials',
      href: '/print-materials/event-materials',
    },
  ];

  return (
    <ul className="library-nav">
      {links.map((link) => (
        <li key={link.href}>
          <a
            href={link.href}
            className={activeItem === link.href ? 'active' : ''}
          >
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default MaterialsSideNav;
