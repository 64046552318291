import React, { useState } from 'react'
import Embed from './Embed';

const ShootsPastVideos = () => {
  const [iframeSrc, setIframeSrc] = useState('https://workdrive.zohopublic.com.au/embed/pmqef74b5e3a8db024ffabf20dc1f86c17260?toolbar=false&layout=grid&appearance=light&themecolor=green');

  const handleLinkClick = (event, newSrc) => {
    event.preventDefault();
    setIframeSrc(newSrc);
  };

  const links = [
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef74b5e3a8db024ffabf20dc1f86c17260?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Tradesman',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef5b93fac0fe41433891286cf772949269?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Explorer',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqef085198ddfdb2465abbe0c2f618610810?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Initiator',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqefcd670a74b55646d19b005d1f2bda40d8?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Companion',
    },
    {
      href: 'https://workdrive.zohopublic.com.au/embed/pmqefc55e4dcc88494b1ca5c5cbf2f85ba051?toolbar=false&layout=grid&appearance=light&themecolor=green',
      text: 'Battery World',
    },
  ];

  return (
    <div className="content photos library light">
      <div className="container">
        <div className="row">
          <div className="col col-10">
            <ul className="library-nav">
              {links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className={iframeSrc === link.href ? 'active' : ''}
                  onClick={(event) => handleLinkClick(event, link.href)}
                >
                  {link.text}
                </a>
              </li>
              ))}
            </ul>
          </div>
          <div className="col col-90">
            <Embed src={iframeSrc} embedHeight="calc(100vw* 3600 / 1900)" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShootsPastVideos