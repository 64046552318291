import React from 'react';
import Embed from './Embed';
import MrtLogo from '../img/mrt-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

const Campaigns = () => {

  const campaignsUrl = 'https://airtable.com/embed/appIrGKJJuwNRaNUj/shrxUHWAonPswrKmA?backgroundColor=gray&amp;viewControls=on';

  return (
    <div className="content campaigns">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>
              <div className="content-body">
                <h2>Campaign Development Process</h2>
                <div className="process-items">
                  <div className="process-item">
                    <span className="arrow"><FontAwesomeIcon icon={faChevronRight} /></span>
                    <h3 className="title">Concept Phase</h3>
                    <p className="desc">Brainstorming potential campaigns - product timeline, product availability, market demands, business demands etc.</p>
                    <p className="desc top-line">Concept Approved: Scope doc created</p>
                  </div>
                  <div className="process-item">
                    <span className="arrow"><FontAwesomeIcon icon={faChevronRight} /></span>
                    <h3 className="title">Scope Phase</h3>
                    <p className="desc">Scope document completed:</p>
                    <ul className="desc">
                      <li>Products</li>
                      <li>Pricing</li>
                      <li>Audience</li>
                      <li>Shoot dates confirmed</li>
                      <li>Assets to be created</li>
                    </ul>
                    <p className="desc flex color-mrt-green">
                      <img src={MrtLogo} alt="MRT Logo" />
                      Sign-off 6wks pre-launch
                    </p>
                    <p className="desc mrt top-line">CAD files provided</p>
                    <p className="desc flex color-mrt-green">
                      <img src={MrtLogo} alt="MRT Logo" />
                      6wks pre-launch
                    </p>
                  </div>
                  <div className="process-item">
                    <span className="arrow"><FontAwesomeIcon icon={faChevronRight} /></span>
                    <h3 className="title">Design Phase</h3>
                    <p className="desc mrt">Initial copy written</p>
                    <p className="desc flex color-mrt-green">
                      <img src={MrtLogo} alt="MRT Logo" />
                      Sign-off 4wks pre-launch
                    </p>
                    <ul className="desc top-line">
                      <li>Videography</li>
                      <li>Video Editing </li>
                      <li>Photography</li>
                      <li>Photo Editing</li>
                      <li>Graphic Design</li>
                      <li>Ad Design</li>
                      <li>Web Design</li>
                      <li>Animation Design</li>
                    </ul>
                    <p className="desc mrt top-line">Design files (Figma) created</p>
                    <p className="desc flex color-mrt-green">
                      <img src={MrtLogo} alt="MRT Logo" />
                      Sign-off 2wks pre-launch
                    </p>
                  </div>
                  <div className="process-item">
                    <span className="arrow"><FontAwesomeIcon icon={faChevronRight} /></span>
                    <h3 className="title">Dev Phase</h3>
                    <ul className="desc">
                      <li>Website coded in staging site</li>
                      <li>SEO techniques</li>
                      <li>Ads created</li>
                      <li>Tracking created</li>
                      <li>All assets created</li>
                    </ul>
                  </div>
                  <div className="process-item">
                    <span className="arrow"><FontAwesomeIcon icon={faChevronRight} /></span>
                    <h3 className="title">Testing &amp; Sign-off </h3>
                    <p className="desc mrt">All parties testing in staging site </p>
                    <p className="desc flex color-mrt-green">
                      <img src={MrtLogo} alt="MRT Logo" />
                      Sign-off 3 days pre-launch
                    </p>
                  </div>
                  <div className="process-item">
                    <h3 className="title">Launch</h3>
                    <ul className="desc">
                      <li>Website pages migrated to live site&nbsp;</li>
                      <li>Final testing by all parties</li>
                      <li>Ads go live</li>
                      <li>Reporting begins</li>
                    </ul>
                  </div>
                </div>
                <Embed src={campaignsUrl} embedHeight="calc(100vw* 7250 / 1900)" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Campaigns;
