import React from 'react'
import Embed from './Embed';

const CadFiles = () => {

  const CadFilesUrl = "https://airtable.com/embed/app83LYJwlbMMFtg1/shrHqxQ5dbxMt3zOr?viewControls=on";

  return (    
    <div className="content cad-files">
      <div className="container">
        <div className="row">
          <div className="col col-100">
            <section>              
              <Embed src={CadFilesUrl} embedHeight="calc(100vw* 2250 / 1900)" /> 
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CadFiles